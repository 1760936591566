import { ConferenceLinkType } from 'types/enums/meeting-rooms';
import { ICateringCategory } from './catering-category';

export interface IMeetingRoomCalendarDataAccessRules {
  isHidden: boolean;
  isRestricted: boolean;
  userHasAccess: boolean;
  restrictedTime: number;
}

export interface IMeetingRoomCalendarDataFloor {
  id: string;
  name: string;
  image: string;
  hasPublishedFloorPlan: boolean;
  order: number;
}

export interface IMeetingRoom {
  id: string;
  name: string;
  capacity: number;
  description: string;
  amenities: string[];
  floorId: string;
  accessRules: IMeetingRoomCalendarDataAccessRules;
  images: string[];
  order: number;
  equipment: IEquipment[];

  conferenceProviders: ConferenceLinkType[];
  syncProvider: string | null;

  serviceRequestCategories?: ICateringCategory[];
}

export interface IMeetingRoomWithFloor extends IMeetingRoom {
  floor: IMeetingRoomCalendarDataFloor;
}

export interface IMeetingRoomCalendarData {
  id: string;
  events: IMeetingRoomCalendarEventData[];
}

export interface IMeetingRoomWithCalendarData extends IMeetingRoom {
  events: IMeetingRoomCalendarData['events'];
}

export interface IMeetingRoomCalendarEventData {
  id?: string;
  bookingId?: string;

  type: 'occurrence' | 'legacy' | 'single';
  subject: string;
  roomId: string;
  shouldMasquerade: boolean;
  hasServiceRequest?: boolean;

  startTime: string;
  endTime: string;

  user: {
    id: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
    avatarColor: string;
  };

  virtualId: string;
  seriesId: string;

  isEditable: boolean;
  isPrivate: boolean;
}

export interface IEquipment {
  id: string;
  title: string;
}

export interface IEquipmentDto {
  id: string;
  title?: string;
}

